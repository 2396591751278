<template>
  <div ref="navbar" class="px-0 py-2 py-md-2 mx-auto bg-white">
    <div class="container-fluid">
      <b-navbar class="py-2 py-sm-1 py-lg-2 px-0 px-md-2 px-lg-0 w-100">
        <div class="header-left d-flex flex-wrap flex-grow-1 align-items-center justify-content-between">
          <MobileSideNav :categories="categories"/>
          <router-link :to="{ name: 'home' }">
            <img @click="setSearchOverlay(false)" v-lazy="require('@/assets/AnyTask.svg')" class="logo ml-lg-2 pr-lg-3 position-relative" width="149" height="50" :alt="anyTasksName"/>
          </router-link>
          <ais-configure :query="this.searchInput" />
          <div class="pt-3 px-0 pt-sm-3 d-flex pt-lg-0 order-3 order-lg-2 col-12 col-lg-auto flex-grow-1" v-if="getAdvanceSearch().top && getAdvanceSearch().suggested">
            <div ref="searchBox" @click="checkSearchboxPosition()" class="d-flex w-100 align-items-center justify-content-start overflow-hidden bg-gray aa-search-box-container">
              <i class="fa-solid fa-magnifying-glass fa-md ml-4 mr-2 text-secondary"></i>
              <AutocompleteSearch :categories="categories" class="flex-grow-1"/>
              <b-button v-if="categoryFilter && searchOverlayOpen" @click="setCategoryFilter(null)" class="rounded-pill py-1 mr-2 d-none d-md-block" variant="primary">
                {{ $t('search_in') }} {{ categoryFilter.slug.includes('other') ? categoryFilter.parentCategory : ' ' }} {{ categoryFilter.name }}
                <i class="fa-solid fa-xmark ml-1"></i>
              </b-button>
            </div>
            <button v-if="searchOverlayOpen" class="btn d-none d-lg-block p-0 ml-2 close-overlay rounded-circle bg-gray" @click="setSearchOverlay(false)">
              <i class="fa-solid fa-xmark fa-lg text-secondary"></i>
            </button>
          </div>
          <ais-search-box v-else-if="getAdvanceSearch().top" class="pb-2 pb-md-0 pt-3 mx-lg-3 pt-sm-3 d-flex pt-lg-0 order-2 order-md-2 flex-grow-1">
            <template v-slot="{ currentRefinement, isSearchStalled, refine }">
              <div class="d-flex w-100 align-items-center justify-content-start rounded-pill overflow-hidden bg-gray search-box-container">
                <i class="fa-solid fa-magnifying-glass fa-md ml-4 text-secondary"></i>
                <input
                  id="searchInput"
                  type="search"
                  :maxlength="maxSearchCharacters"
                  v-model="advancedSearchInputValue"
                  :placeholder="$t('home.search.placeholder')"
                  @input="onAdvanceSearchSubmit(refine, $event.currentTarget.value, false), pushDataLayer($event.currentTarget.value)"
                  @keydown.enter="onAdvanceSearchSubmit(refine, $event.currentTarget.value, true)"
                  @focus="onAdvanceSearchSubmit(refine, $event.currentTarget.value, true)"
                  @click="scrollTop()"
                  class="border search-box border-0 px-3 bg-gray w-100"
                >
                <div class="ml-auto d-flex">
                  <button v-if="advancedSearchInputValue && searchOverlayOpen" v-on:click="onAdvanceSearchSubmit(refine, '', true), advancedSearchInputValue = null" class="border-0 bg-transparent mr-2 text-secondary">
                    {{ $t('home.search.clear') }}
                  </button>
                  <b-button v-if="categoryFilter && searchOverlayOpen" @click="setCategoryFilter(null)" class="rounded-pill mr-2 d-none d-md-flex align-items-center" variant="primary">
                    <span>{{ $t('search_in') }}</span> <span class="d-md-block d-lg-none d-xl-block ml-1">{{ categoryFilter.slug.includes('other') ? categoryFilter.parentCategory : ' ' }}</span> <span class="ml-1">{{ categoryFilter.name }}</span>
                    <i class="fa-solid fa-xmark ml-2"></i>
                  </b-button>
                </div>
              </div>
              <button v-if="searchOverlayOpen" class="btn p-0 ml-2 close-overlay d-none d-md-block rounded-circle bg-gray" @click="setSearchOverlay(false), refine('')">
                <i class="fa-solid fa-xmark fa-lg text-secondary"></i>
              </button>
            </template>
          </ais-search-box>
          <b-form v-else @submit.prevent="onSubmit" id="nav-search" class="pt-2 pt-sm-3 pt-lg-0 mt-0 mt-lg-2 order-2 order-lg-1 d-flex align-items-center justify-content-start flex-grow-1 ">
            <b-input-group size="sm" class="w-100 bg-gray rounded-pill overflow-hidden w-lg-auto d-flex align-items-center">
              <b-form-input class="border-0 px-4 bg-transparent" id="nav-search-input" v-model="searchInput" ref="searchInput" :placeholder="$t('home.search.placeholder')"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" class=" px-3 standard-search-button" size="lg" type="submit">
                  <i class="fa-solid fa-magnifying-glass fa-lg pr-1"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div class="advanced-search-nav mt-0 mt-lg-0 py-1 px-lg-2" v-on:click="$bvModal.show('advanced-search')">
              <span>{{$t('advanced_search.title')}}</span>
            </div>
          </b-form>
          <b-navbar-nav class="d-flex p-0 account-nav order-1 order-lg-2 flex-grow-0 justify-content-end">
            <UserMenu />
            <div class="d-none ml-3 d-lg-flex flex-row align-items-center">
              <template v-if="hasCurrencies">
                <CurrencyButton />
              </template>
            </div>
          </b-navbar-nav>
        </div>
      </b-navbar>

      <SignupPopup />
      <Newsletter />
      <LoginModal/>
      <LoginModal :ssoError="$t('login_reason_sso')" id="loginSso"/>
      <FirstTimeUserModal/>
      <ForgottenPassword/>
      <AdvancedSearch :categories="categories" />
    </div>
  </div>
</template>

<script>
import config from '../../config'
import { mapActions, mapGetters, mapState } from 'vuex'
import LoginModal from '../Global/Login'
import FirstTimeUserModal from '../Modal/FirstTimeUserModal'
import ForgottenPassword from '../MyAccount/ForgottenPassword'
import Newsletter from '../Modal/Newsletter'
import AdvancedSearch from '../Modal/AdvancedSearch'
import SignupPopup from '../../components/Global/SignupPopup.vue'
import UserMenu from './UserMenu'
import CurrencyButton from './CurrencyButton'
import AutocompleteSearch from '../AdvancedSearch/AutocompleteSearch'
import MobileSideNav from './MobileSideNav'

export default {
  name: 'NavBar',
  components: {
    ForgottenPassword,
    FirstTimeUserModal,
    LoginModal,
    Newsletter,
    AdvancedSearch,
    SignupPopup,
    CurrencyButton,
    UserMenu,
    AutocompleteSearch,
    MobileSideNav
  },
  props: {
    categories: Array
  },
  data () {
    return {
      anyTasksName: config.anytasks_name,
      social: config.social,
      beta: config.beta,
      searchInput: this.$route.query.terms,
      profilePhoto: localStorage.getItem('profile_picture'),
      role_seller: localStorage.getItem('role_seller'),
      unreadMessages: 0,
      profile_incomplete: null,
      homePage: false,
      expandedSearch: false,
      locale_translated: config.locale_translated,
      language: localStorage.getItem('language'),
      errorPage: '',
      minSearchCharacters: config.advanced_search.min_characters,
      maxSearchCharacters: config.advanced_search.max_characters,
      advancedSearchInputValue: null,
      showMobileLoginMenu: false
    }
  },
  mounted: function () {
    if (this.getAdvanceSearch().top && this.getAdvanceSearch().suggested) {
      const observer = new ResizeObserver(() => {
        this.checkSearchboxPosition()
      })
      observer.observe(this.$refs.navbar)
    }
    if (this.$route.query.query && this.getAdvanceSearch().top && !this.getAdvanceSearch().suggested) {
      let query = this.$route.query.query
      for (let i = 0; i < this.categories.length; i++) {
        for (let index = 0; index < this.categories[i].child.length; index++) {
          if (!this.categories[i].child[index].divider && this.$route.query.query.includes(this.categories[i].child[index].name_en)) {
            let subcategory = this.categories[i].child[index]
            if (this.$route.query.query.includes(this.categories[i].parent.name_en + ' Other')) {
              this.setCategoryFilter({ name: subcategory.name, slug: subcategory.slug, nameEnglish: subcategory.name_en, parentCategory: this.categories[i].parent.name, parentCategoryEnglish: this.categories[i].parent.name_en })
              query = this.$route.query.query.replace(this.categories[i].parent.name_en + ' Other', '')
              if (query[0] === ' ') {
                query = query.slice(1, query.length)
              }
            } else if (!subcategory.slug.includes('other')) {
              this.setCategoryFilter({ name: subcategory.name, slug: subcategory.slug, nameEnglish: subcategory.name_en, parentCategory: this.categories[i].parent.name, parentCategoryEnglish: this.categories[i].parent.name_en })
              query = this.$route.query.query.replace(subcategory.name_en, '')
              if (query[0] === ' ') {
                query = query.slice(1, query.length)
              }
            }
          }
        }
      }
      this.advancedSearchInputValue = query
    }

    const self = this
    window.onpopstate = function () {
      if (!self.$route.query.query) {
        self.setSearchOverlay(false)
        document.getElementById('searchInput').blur()
      }
    }
    if (localStorage.getItem('profile_incomplete')) {
      this.$bvModal.show('user-profile')
    }

    if (localStorage.getItem('user-currency') !== null) {
      this.setCurrency(localStorage.getItem('user-currency'))
    }
  },
  watch: {
    searchOverlayOpen (to, from) {
      this.scrollTop()
    },
    categoryFilter (to, from) {
      if (to !== from && !this.getAdvanceSearch().suggested) {
        this.scrollTop()
        document.getElementById('searchInput') && document.getElementById('searchInput').focus()
        document.getElementById('searchInput') && document.getElementById('searchInput').blur()
      }
    },
    profile_incomplete (newProfileIncomplete) {
      this.$bvModal.show('user-profile')
      this.newProfile_incomplete = newProfileIncomplete
    },
    $route (to, from) {
      let routeName = this.$route.name
      this.homePage = routeName === 'home' || routeName === 'meet_the_sellers'

      if (routeName === 'search') {
        this.searchInput = this.$route.query.terms
        this.advancedSearchInputValue = this.searchInput
      }

      if (routeName === 'connecting-etn') {
        this.hideMobileCategories = true
      }

      if (routeName === '500') {
        this.errorPage = '500'
      }
    }
  },
  methods: {
    ...mapActions([
      'signOut',
      'setNewMessageCount',
      'processJwt',
      'setCurrency',
      'setSearchOverlay',
      'setCategoryFilter'
    ]),
    checkSearchboxPosition () {
      if (this.getAdvanceSearch().top && this.getAdvanceSearch().suggested) {
        const el = this.$refs.searchBox.getBoundingClientRect()
        let targetStyleSheet = null
        let targetRule = null
        for (let styleSheet = 0; styleSheet < document.styleSheets.length; styleSheet++) {
          for (let cssRule = 0; cssRule < document.styleSheets[styleSheet].cssRules.length; cssRule++) {
            if (document.styleSheets[styleSheet].cssRules[cssRule].selectorText === '.aa-Panel') {
              targetRule = cssRule
              targetStyleSheet = styleSheet
            }
          }
        }
        document.styleSheets[targetStyleSheet].deleteRule(targetRule)
        document.styleSheets[targetStyleSheet].insertRule(`
          .aa-Panel {
            left: ${el.left}px !important;
            right: ${el.right}px !important;
            top: ${el.top + el.height}px !important;
            width: ${el.width}px !important;
            position: fixed;
            padding: 0 !important;
            z-index: 1030;
            margin-top: -2px !important;
            border-radius: 0 0 25px 25px;
            -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,.15);
            box-shadow: 0 5px 12px 0 rgba(0,0,0,.15);
            border: #d4d4d4 1px solid;
            background-color: white;
            overflow: clip;
          }`
        )
      }
    },
    pushDataLayer (query) {
      setTimeout(() => {
        if (this.advancedSearchInputValue === query && config.google_tag_manager !== '' && config.tracking_enabled === 'true' && query) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'search',
            'search_terms': query
          })
        }
      }, 2500)
    },
    onAdvanceSearchSubmit (refine, value, byPass = false) {
      this.advancedSearchInputValue = value
      this.scrollTop()
      if (this.categoryFilter && value === '') {
        byPass = true
      }
      if ((!byPass && value.length < this.minSearchCharacters) || value[value.length - 1] === ' ' || value.includes('  ')) {
        return
      }
      this.setSearchOverlay(true)
      if (this.categoryFilter) {
        if (this.categoryFilter.slug.includes('other')) {
          refine(this.categoryFilter.parentCategoryEnglish + ' ' + this.categoryFilter.nameEnglish + ' ' + value)
        } else {
          refine(this.categoryFilter.nameEnglish + ' ' + value)
        }
      } else {
        refine(value)
      }
    },
    scrollTop () {
      window.scrollTo(0, 0)
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 250)
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.searchInput !== undefined) {
        this.searchInput = this.searchInput.replace(/[^\w\s+]/gi, '')
      }
      this.$router.push({ name: 'search', query: { terms: this.searchInput } })
    }
  },
  computed: {
    ...mapGetters(['getCurrencyRates', 'getCurrency', 'getAdvanceSearch', 'getCategoryFilter', 'isLoggedIn']),
    ...mapState(['user']),
    ...mapState({ searchOverlayOpen: state => state.search.searchOverlayOpen, categoryFilter: state => state.search.categoryFilter }),
    hasCurrencies () {
      return Object.keys(this.getCurrencyRates()).length > 0
    },
    getSelectedCurrency () {
      return this.getCurrency()
    }
  }
}
</script>

<style lang="scss">

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
.aa-ItemContentTitle {
  overflow-y: visible;
}

.aa-Form {
  border: none;
  padding-left: 10px;
  background-color: #F5F5F5 !important;

  &.queries-open {
    background-color: white !important;
  }

  &:focus-within {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .aa-InputWrapperPrefix {
    display: none;
  }

  .aa-InputWrapperSuffix {
    .aa-ClearButton {
      .aa-ClearIcon {
        &::before {
          background: #00bc25;
          display: inline-block;
          width: 32px;
          height: 32px;
          content: 'clear';
        }
      }
    }
  }
}
.aa-PanelLayout {
  padding: 0 !important;
  section {
    ul {
      li {
        padding: 7px 20px;
        border-bottom: 1px #d4d4d4 solid;
        border-radius: 0;
      }
    }
    &:last-of-type {
      ul {
        li {
          .aa-ItemIcon {
            opacity: 0;
          }
          &:last-of-type {
            border-bottom: none;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

#NavBar.search-active:after {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: 1;
}

.navbar-light .account-nav.navbar-nav .nav-link {
  span.pt-2 {
    padding: 0 !important;
    display: inline-block;
  }
  span.flag {
    margin-top: -14px;
  }
  &.dropdown-content {
    padding: 0 !important;
    max-height: 38px;
  }
  &:focus-visible {
    outline: none;
  }
}

.toggler {
  width: 68px;
}

.search-box-container {
  border: 2px #F5F5F5 solid !important;
  border-radius: 25px;

  &.queries-open {
    background-color: white !important;
    border: #d4d4d4 1px solid !important;
    border-radius: 25px 25px 0 0px;
  }

  .search-box {
    outline: none;
    background-color: transparent;
    padding-block: 12px;
    &:focus {
      background-color: transparent !important;
    }
    padding-block: 8px;
  }
}

.aa-search-box-container {
  border: 1px #F5F5F5 solid !important;
  border-radius: 25px;
  &.queries-open {
    background-color: white !important;
    border: #d4d4d4 1px solid !important;
    border-radius: 25px 25px 0 0px;
  }

  input {
    outline: none;
    padding-block: 8px;
  }
}

.close-overlay {
  height: 46px;
  width: 46px;

  &:hover {
    background-color: #efefef !important;
  }
}

.standard-search-button {
  padding-block: 12px !important;
}

.navbar-nav .nav-link {
  padding: 0 !important;
}

.advanced-search-nav {
  font-size: .8em;
  color: $brand-sky;
  cursor: pointer;
}

#nav {
  .dropdown-content {
    ul {
      max-height: 60vh;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        background-color: #fff;
        width: 14px
      }
      &::-webkit-scrollbar-track {
        background-color: #fff
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 14px;
        border: 4px solid #fff
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
  .category-dropdown-button {
    &:hover {
      .dropdown-content {
        ul {
          overflow-y: auto;
        }
      }
    }
  }
}

.header-left {
  align-items: center;
}

.navbar-nav .b-nav-dropdown {
  display: flex;
  align-items: center;
}
.navbar-expand > .container {
  padding: 0 15px !important;
}

.dropdown-item {
  display: flex !important;
  flex-direction: row;
}
</style>

<style lang="scss" scoped>

a {
  color: #777 !important;
}

.legal {
  font-size: 11px;
  line-height: 120%;
}

.navbar-toggler {
  border: none;
  padding: 0;
  font-size: 1rem !important;
}

.search-icon label {
  background-color: #ffffff;
}

.logo {
  min-width: 100px;
  height: 50px !important;
}

.header-left {
  display: inherit;
}

.closeLangBtn {
  z-index: 999;
}

.lang-mobile-title {
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
}

/*End of Nav*/

#nav-collapse {
  a {
    &:link {
      color: #575757;
    }
    &:visited {
      color: #575757;
    }
    &:active {
      background-color: #dedede;
    }
  }
  ul {
    list-style-type: none;
  }
}

#searchInput:focus-visible {
  outline: none !important;
}

#nav-search {
  #nav-search-input {
    font-size: 1rem;

    &:focus,
    &:active {
      outline: 0 !important;
      border-color: #ced4da;
      background-image: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
}
@media (max-width: $breakpoint-lg - 1) {
  #nav-search {
    min-width: 500px;
  }

  .ais-SearchBox {
    width: 100%;
  }
}

@media (max-width: $breakpoint-sm - 50) {
  #nav-search {
    min-width: initial !important;
  }

  .aa-search-box-container {
    .search-box {
      margin-left: 40px;
      padding-block: 8px;

      &::-webkit-input-placeholder {
        text-align: center;
        margin-left: 40px;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }
  }

  .logo {
    max-height: 44px;
  }
}
@include md-up {
  #nav-search {
    position: relative;
    min-width: 600px;
    top: -5px;
  }
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.nav-bar {
  .navbar-nav {
    align-items: center;
    max-height: 58px;
  }
}

#NavBar {
  button {
    font-size: .75rem;
    @include sm-up {
      font-size: 1rem;
    }
  }
}
</style>

<style lang="scss">

@keyframes heartbeat {
  0% {
    transform: scale(.6);
    opacity: 0;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.nav-link {
  .rounded-circle {
    box-shadow: $navbar-icon-shadow;
    width: 36px;
    height: 36px;
    border-radius: 24px;
  }
}

@media (max-width: 281px) {
 .toggler {
   width: 21px;
   background-color: #00bc25;
 }

  .logo {
    width: 110px;
  }
}
</style>
