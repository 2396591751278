<template>
  <div>
    <b-container fluid>
      <b-col cols="12" class="mb-2 pt-md-0 my-md-0 px-0 px-md-2 d-md-flex d-lg-block align-items-center flex-row">
        <div v-if="category" class="category-banner my-2 py-lg-2">
          <div class="banner-content pt-3 py-lg-4">
            <div class="mb-2 d-flex px-3 justify-content-end">
              <b-button @click="setCategoryFilter(null)" size="sm" variant="light" class="d-md-none search-all-button rounded-pill px-3">{{ $t('remove_category_filter') }}<i class="fa-solid fa-xmark fa-sm ml-2"></i></b-button>
            </div>
            <div class="banner-text-overlay text-white d-flex flex-col justify-content-center">
              <h2 class="w-100 font-weight-bold px-4 pb-0 pt-lg-1">{{ category.name }}</h2>
              <h5 class="mx-auto mb-4 pb-2 pb-lg-3 pb-md-0 mb-lg-0">{{ category.summary }}</h5>
            </div>
          </div>
          <div class="banner-image-holder d-flex align-items-center justify-content-between">
            <img class="banner-image" :alt="category.name" :src="getAsset()">
          </div>
        </div>
        <div class="filter-holder mb-lg-2 mt-lg-3 flex-grow-1">
          <AdvancedSearchFiltersHolder ref="advancedFilters" class="px-lg-2"/>
        </div>
        <div class="d-flex d-lg-none mr-2 pt-1 justify-content-between mr-md-auto">
          <SortByHolder />
        </div>
        <div class="d-flex px-1 mt-2 mb-lg-3 ml-lg-2">
          <ais-stats class="text-left">
            <template v-slot="{ hitsPerPage, nbPages, nbHits, page, processingTimeMS, query }">
              <div class="text-center text-lg-left" v-if="nbHits > 0">
                {{ $t('showing_results', {
                'from': nbHits === 0 ? 0 : ((page + 1) * hitsPerPage) - (hitsPerPage - 1),
                'to': (page + 1) * hitsPerPage > nbHits ? nbHits : (page + 1) * hitsPerPage,
                'total': nbHits > 1000 ? 1000 : nbHits
              }) }}
              </div>
              <div v-else></div>
            </template>
          </ais-stats>
        </div>
      </b-col>
    </b-container>
    <ais-state-results>
      <template v-slot="{ status }">
        <ais-hits>
          <template v-slot="{ items, sendEvent }">
            <b-container fluid>
              <b-col cols="12">
                <b-row v-if="items.length" class="card-holder pb-3">
                  <div v-for="(item) in items" :key="item.objectID" class="col-lg-3 px-0 col-md-6 col-sm-6 px-sm-2 col-12 card-item my-2" @click="sendEvent('click', item, 'Task Clicked')">
                    <Card :isFeaturedTask="false" :deliveryTime="item.packages[0].delivery" :taskImage="formatMobileUrl(item.assets[0]['path'], item.assets[0]['type'])" :carouselImage="item" :username="item.user.username" :slug="item.slug"
                          :user_rating="item.user.review_average" :user_count="item.user.review_count" :user_online="item.user.last_active ? isUserOnline(item.user.last_active) : false"
                          :imageIcon="item.user.photo_path" :description="item.title" :rating="item.review_average" :count="item.review_count"
                          :price="settings.service_rate ? item.packages[0].price + (item.packages[0].price * settings.service_rate / 100) : item.packages[0].price"
                          :hash="item.objectID" :select="isFavourite(item.objectID)" :sellerInfo="item.user" :queryID="item.__queryID"
                          :primaryCategorySlug="item.categories[0].slug" :isHeroTask="item.hero" location="search-results"
                          class="mx-auto ml-sm-0 mr-sm-0 w-100"/>
                  </div>
                </b-row>
                <div v-else-if="!items.length && status !== 'loading' && status !== 'stalled'">
                  <b-row>
                    <div class="mx-auto no-saved-tasks my-4">
                      <h3 class="font-weight-bold">{{ $t('search_no_tasks_message')}}</h3>
                    </div>
                  </b-row>
                </div>
                <div v-else class="d-flex justify-content-center w-100 mt-4 mb-5">
                  <b-spinner large label="Loading"></b-spinner>
                </div>
                <div v-if="status !== 'loading'" class="list-job-banner mb-4">
                  <div class="py-4  d-flex flex-col align-items-center justify-content-center">
                    <h4 class="w-100 mb-1 text-white font-weight-bold px-4 pb-0 pt-lg-1">{{ $t('list_job_search_banner.heading') }}</h4>
                    <h5 class="mb-2 pb-1 px-5 text-white">{{ $t('list_job_search_banner.sub_heading') }}</h5>
                    <b-button @click="resultsButtonTrack(), isLoggedIn ? $router.push({ name: 'task_or_job', params: { referrer: 'search_results_banner' } }) : listJobLogin()" variant="light" class="rounded-pill py-2 px-4">{{ $t('list_job_search_banner.button') }}</b-button>
                  </div>
                </div>
              </b-col>
            </b-container>
          </template>
        </ais-hits>
      </template>
    </ais-state-results>
    <advanced-search-pagination />
    <div class="mx-4 seo-text" v-if="this.category">
      <b-col cols="12" v-if="this.category.body_content">
        <hr>
        <!-- SEO TEXT -->
        <div v-if="this.category.body_content" v-html="this.$sanitize(this.category.body_content, {
          allowedTags: [ 'h3', 'h5', 'h4', 'p', 'ul', 'li', 'div' ],
                allowedClasses: {
                  'div': [ 'faqs', 'flex-*', 'row', 'mx-*', 'text-*', 'font-weight-*', 'entry', 'm*', 'p*', 'col-*'],
                  'h3': [ 'flex-*', 'mx-*', 'text-*', 'font-weight-*', 'm*'],
                  'h5': [ 'flex-*', 'mx-*', 'text-*', 'font-weight-*', 'm*'],
                  'h4': [ 'text-*', 'font-weight-*', 'm*'],
                  'p': [ 'text-*', 'font-weight-*', 'm*'],
                  'ul': [ 'text-*', 'font-weight-*', 'm*'],
                  'li': [ 'text-*', 'font-weight-*', 'm*'],
                }
        })"></div>
      </b-col>
    </div>
  </div>
</template>

<script>
import AdvancedSearchPagination from '../../components/AdvancedSearch/AdvancedSearchPagination'
import AdvancedSearchFiltersHolder from './AdvancedSearchFiltersHolder'
import Card from '../Task/Card'
import helper from '../../helper'
import config from '../../config'
import { mapActions, mapState, mapGetters } from 'vuex'
import ApiService from '../../apiService'
import SortByHolder from './SortByHolder'

export default {
  name: 'AdvancedSearch',
  components: {
    Card,
    AdvancedSearchPagination,
    AdvancedSearchFiltersHolder,
    SortByHolder
  },
  data () {
    return {
      settings: JSON.parse(sessionStorage.getItem('settings')),
      currentPage: this.getPage(),
      currentSort: this.$route.query.sortBy,
      category: null,
      bannerImagePath: null,
      parentBannerImagePath: null
    }
  },
  watch: {
    categoryFilter () {
      if (this.categoryFilter) {
        this.setCategory(this.categoryFilter.slug)
      } else {
        this.category = null
      }
    }
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', helper.pauseVideoOnScroll)
  },
  methods: {
    ...mapActions(['setFiltersOpen', 'setCategoryFilter']),
    resultsButtonTrack () {
      this.$tracker.tagEvent('listJobResultsButtonClick')
    },
    listJobLogin () {
      localStorage.setItem('listJobLogIn', 'true')
      this.$bvModal.show('login')
    },
    getAsset () {
      try {
        return require('../../assets/Categories/CategoryPageBanners/' + this.bannerImagePath)
      } catch (ex) {
        return require('../../assets/Categories/CategoryPageBanners/' + this.parentBannerImagePath)
      }
    },
    setCategory (slug) {
      return ApiService.getCategoryAndItsChildren(slug).then(response => {
        this.category = response.data.data[0]
        this.bannerImagePath = (response.data.data[0].image_path.slice(0, -4) + '_banner.jpg').replace('/', '_')
        this.parentBannerImagePath = (response.data.data[0].parent_category.image_path.slice(0, -4) + '_banner.jpg').replace('/', '_')
      }).catch((error) => {
        ApiService.showError(error)
      })
    },
    getCurrentSortBy (items, currentRefinement) {
      const currentItem = items.filter(item => item.value === currentRefinement)
      return currentItem[0].label
    },
    formatMobileUrl (filename, filetype) {
      return helper.formatMobileUrl(filename, filetype)
    },
    getPage () {
      if (this.$route.query.page && !isNaN(this.$route.query.page)) {
        return this.$route.query.page
      } else {
        return 1
      }
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    }
  },
  computed: {
    ...mapState({ categoryFilter: state => state.search.categoryFilter, filtersOpen: state => state.search.filtersOpen, activeFilters: state => state.search.activeFilters }),
    ...mapGetters([
      'isFavourite',
      'getCategoryFilter'
    ]),
    isLoggedIn () {
      return this.$store.state.accessToken !== null && this.$store.state.accessToken !== undefined
    },
    config () {
      return config
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .btn:focus {
  box-shadow: none !important;
}

a:hover {
  text-decoration: none;
}

.category-banner {
  position: relative;
  width: 100%;
  .banner-content {
    position: relative;
    z-index: 1;
    button {
      opacity: 0.75;
    }
    h5 {
      font-size: 1.1rem;
      width: 80%;
      @include md-up {
        font-size: 1.2rem;
      }
      @include lg-up {
        width: 100%;
        font-size: 1.3rem;
      }
    }

  }
  .search-all-button {
    opacity: 0.8;
    bottom: 12px;
    right: 12px;
  }
  .banner-image-holder {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 20px;
    .banner-image {
      opacity: 0.7;
      object-fit: cover;
      height: 100%;
      width: 100%;
      filter: blur(2px);
    }
    &::after {
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: linear-gradient(to bottom, rgba(44, 41, 37, 0.8), rgba(72, 67, 55, 0.5));
      content: ' ';
    }
  }
}

.list-job-banner {
  overflow: hidden;
  border-radius: 15px;
  background-image: linear-gradient(to left, #6D56FE, #9FC9EA);
}

.filter-holder {
  @include sm-up {
    max-width: 200px;
  }
  @include lg-up {
    max-width: unset;
  }
}
</style>
